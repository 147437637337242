.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 35px;
  
    width: 100%;
  }
  
  .list__menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 20px;
  }
  
  .list__menu-button-add {
    background-color: transparent;
    border: none;
    color: rgba(224, 224, 224, 0.7);
    display: flex;
    align-items: center;
    column-gap: 10px;
    text-align: left;
    font-size: 14px;
    font-family: inherit;
    padding: 0;
  
    cursor: pointer;
  }
  
  .list__menu-button-add-icon {
    color: #13f452;
    width: 35px;
    height: 35px;
  }
  
  .list__menu-button-show-filters {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
  
    cursor: pointer;
  }
  
  .list__menu-button-show-filters-icon {
    width: 30px;
    height: 30px;
    fill: #757779;
    display: none;
  }
  
  .list__table-item {
    background-color: #141625;
    border: 1px solid rgba(255, 255, 255, 0.5);
    width: 100%;
    padding: 15px 20px 15px 10px;
    border-radius: 8px;
  
    position: relative;
  
    @media (min-width: 1200px) {
      padding: 30px 45px;
    }
  }
  
  .list__table-item-title {
    color: rgba(15, 247, 81, 1);
    font-size: 24px;
    font-weight: 300;
  }
  
  .list__table-item-data {
    margin-top: 25px;
  
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  
  .list__table-item-data-row {
    display: flex;
    column-gap: 25px;
  }
  
  .list__table-item-data-title {
    width: 40%;
  
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
  }
  
  .list__table-item-data-text {
    width: 60%;
  
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-family: "Geologica", var(--sans-serif);
    font-weight: 300;
    line-height: 16px;
  }
  
  .list__table-item-data-text-postfix {
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.5);
  }
  
  .list__item {
    background-color: #141625;
    border: 1px solid rgba(255, 255, 255, 0.5);
    width: 100%;
    padding: 15px 20px 15px 10px;
    border-radius: 8px;
  
    @media (min-width: 1200px) {
      padding: 30px 45px;
    }
  }

  .list__item--bg-interactive:hover {
    background-color: #252a47;
  }
  
  .list__item-content {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    position: relative;
    line-height: 1;
  }
  
  .list__item-button-goto {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: none;
    padding: 0;
  
    cursor: pointer;
  }

  .list__item-button-copy {
    position: absolute;
    right: -5px;
    top: 25px;
    background-color: transparent;
    border: none;
    padding: 0;
  
    cursor: pointer;

    @media (min-width: 1200px) {
      right: -45px;
      top: -30px;
    }
  }
  
  .list__item-button-goto--top {
    top: 17px;
    bottom: auto;
    right: 40px;
  
    @media (min-width: 1200px) {
      top: 25px;
    }
  }
  
  .list__item-button-goto-icon {
    fill: #2c73bb;
    width: 35px;
    height: 35px;
  }

  .list__item-button-copy-icon {
    fill: #9e9e9e;
    width: 40px;
    height: 40px;
  }
  
  .list__item-title {
    color: rgba(30, 136, 229, 1);
    font-size: 16px;
    line-height: 18px;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
  }
  
  .list__item-title--start {
    justify-content: flex-start;
    column-gap: 20px;
  }
  
  .list__item-title--white {
    color: rgba(255, 255, 255, 1);
  }
  
  .list__item-title--gray {
    color: rgba(224, 224, 224, 0.6);
  }
  
  .list__item-title-text {}
  
  .list__item-title-postfix {
    flex-shrink: 0;
    color: rgba(15, 247, 81, 1);
  }
  
  .list__item-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    align-items: start;
    font-size: 14px;
    font-weight: 200;
    line-height: 25px;
  }

  .list__item-text--green {
    color: rgba(15, 247, 81, 1);
  }
  
  .list__item-text--tiny {
    line-height: 16px;
  }
  
  .list__item-text--padded {
    @media (min-width: 1200px) {
      margin-left: 55px;
    }
  }
  
  .list__item-text--gray {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .list__item-text--green {
    color: rgba(15, 247, 81, 1);
  }
  
  .list__item-text--red {
    color: rgba(255, 84, 71, 1);
  }
  
  .list__item-text--blue,
  a.list__item-text--blue {
    color: rgba(30, 136, 229, 1);
  }
  
  .list__item-text--end {
    align-self: end;
    max-width: 310px;
  }
  
  .list__item-text--lh16 {
    line-height: 16px;
  }
  
  
  .list__item-text-prefix {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: 200;
    margin-right: 10px;
  }
  
  .list__item-text-postfix {
    flex-shrink: 0;
    position: relative;
  }
  
  .list__item-text-postfix-icon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: -23px;
    left: 0;
  }
  
  .list__pagination {
    display: flex;
    column-gap: 5px;
  }
  
  .list__pagination-item {
    width: 28px;
    height: 28px;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    cursor: pointer;
  }
  
  .list__pagination-item--active {
    background-color: #131723;
    border: 1px solid #007aff;
    border-radius: 5px;
    cursor: default;
  }