.icon {
    flex-shrink: 0;
}

.icon--style-vars {
    fill: var(--fill);

    width: var(--width);
    height: var(--height);

    margin: var(--margin, 0);

    @media (min-width: 1200px) {
        width: var(--width2, var(--width));
        height: var(--height2, var(--height));

        margin: var(--margin2, var(--margin, 0));
    }
}

.icon--20x20 {
    width: 20px;
    height: 20px;
}

.icon--24x24 {
    width: 24px;
    height: 24px;
}

.icon--26x26 {
    width: 26px;
    height: 26px;
}

.icon--30x30 {
    width: 30px;
    height: 30px;
}

.icon--40x40 {
    width: 40px;
    height: 40px;
}

.icon--60x60 {
    width: 60px;
    height: 60px;
}

.icon--blue {
    fill: #1e88e5;
}

.icon--gray {
    fill: rgba(255, 255, 255, 0.5);
}

.icon--green {
    fill: #0ff751;
}

.icon--red {
    fill: rgba(255, 84, 71, 1);
}

.icon--margin-top-m5 {
    margin-top: -5px;
}