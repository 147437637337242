.main {
    display: flex;
    flex-direction: column;
}

.main--padded {
    padding: 60px 40px;
}

.main--gaps {
    row-gap: 40px;

    @media (min-width: 1200px) {
        row-gap: 100px;
    }
}

.main--gaps-static {
    row-gap: 40px;
}

.main__title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;

    @media (min-width: 1200px) {
        font-size: 36px;
    }
}

.main__car-list,
.main__car-part-parameters,
.main__car-maintenance-list,
.main__car-document-list,
.main__car-reminder-list,
.main__helpful-services-list,
.main__burger-menu {
    max-width: 574px;
    margin: 0 auto;
}

.main__user-auth-switch,
.main__user-login,
.main__user-registration,
.main__user-profile,
.main__company-info,
.main__company-appointment-form,
.main__company-callback-form {
    max-width: 722px;
    margin: 0 auto;
}

.main__dialogs,
.main__selects,
.main__lists,
.main__filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;

    width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}