.user-profile {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
  
  .user-profile__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .user-profile__rating {
    display: flex;
    align-items: center;
    column-gap: 7px;
    font-size: 24px;
  }
  
  .user-profile__rating-icon-star {
    width: 30px;
    height: 30px;
    fill: rgba(30, 136, 229, 1);
  }
  
  .user-profile__rating-icon-info {
    width: 20px;
    height: 20px;
    fill: rgba(255, 255, 255, 0.5);
  }
  
  .user-profile__rating-icon-info:hover {
    cursor: pointer;
  }
  
  .user-profile__id {
    color: rgba(255, 255, 255, 0.5);
    font-size: 24px;
    font-family: inherit;
  }
  
  .user-profile__id::before {
    content: "ID ";
  }