.label {
    background-color: #2785d5;
    font-size: 12px;
    font-weight: 400;
    padding: 3px 5px;
    text-transform: uppercase;
    border-radius: 999px;
}

.label--wide {
    min-width: 150px;
    text-align: center;
}

.label--centered {
    margin: 0 auto 40px;
    transform: translateX(-20px);
}

.label--big {
    background-color: transparent;
    padding: 5px;
    border: 2px solid #2785d5;
    font-size: 16px;
    font-weight: 400;
    min-width: 320px;
}