.car-list {}

.car-list__content {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.car-list__items {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
}

.car-list__items-header {
    font-family: inherit;
    font-size: 20px;

    @media (min-width: 1200px) {
        font-size: 26px;
    }
}

.car-list__items-header--gray {
    color: rgba(255, 255, 255, 0.5);
}

.car-list__items-button-add {
    font-size: 16px;
    color: rgba(224, 224, 224, 0.7);
}

.car-list__items-button-add-icon {
    width: 25px;
    height: 25px;
    fill: rgba(15, 247, 81, 1);
}

.car-list__items-hint {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(18, 18, 32, 0.5);
    font-family: inherit;
    border-radius: 6px;
    padding: 5px 12px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    text-align: left;
    line-height: 16px;
}