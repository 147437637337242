.hint {
}


.hint__icon {
    --fill: #646a7a;
    --width: 20px;
}

.hint__icon:hover {
    --fill: #9a9ba1;
    --width: 20px;
    cursor: pointer;
}

