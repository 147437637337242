.offer {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    position: relative;

    /*
        @todo
        Фикс проблемы жестко заданных отступов между
        карточками навигации. Жесткие ограничения приводят
        к тому что блок с карточками расположен не по центру экрана.
        
        Вследствие этого текст оффера выходить за правую границу
        блока с карточками навигации.
    */
    padding-right: 25px;
}

.offer__title {
    font-size: 18px;
    line-height: 18px;
    font-weight: 300;

    margin: 0;
    max-width: 330px;

    @media (min-width: 1200px) {
        font-size: 34px;
        line-height: 38px;
        max-width: none;
    }
}

.offer__title--with-logo {
    padding-left: 110px;

    @media (min-width: 1200px) {
        padding-left: 240px;
    }
}

.offer__title--uppercase {
    text-transform: uppercase;
}

.offer__text {
    font-size: 14px;
    font-weight: 200;
    color: rgba(255, 255, 255, 0.5);
    line-height: 16px;
    width: 94%;
    margin: 0;

    @media (min-width: 1200px) {
        font-size: 20px;
        line-height: 20px;
    }
}

.offer__text--with-logo {
    @media (min-width: 1200px) {
        padding-left: 240px;
    }
}

.offer__text-highlight {
    color: rgb(30, 136, 229);
}

.logo {
    display: inline-block;
    width: 114px;
    height: 68px;
    background-image: url("https://274418.selcdn.ru/cv08300-33250f0d-0664-43fc-9dbf-9d89738d114e/uploads/362756/4e845317-f1fd-4061-84c5-1b2af7886e55.png");
    background-size: cover;
    position: absolute;
    left: -15px;
    top: -20px;

    @media (min-width: 1200px) {
        width: 230px;
        height: 135px;
    }
}