
.file-loader {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 5px;
}

.file-loader--left {
    margin-right: auto;
    width: fit-content;
}

.file-loader__list {
    display: flex;
    column-gap: 5px;
    flex-wrap: wrap;
    row-gap: 5px;
}

.file-loader__file {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
    height: 91px;
    border: none;
    outline: none;
    background-color: #141625;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 0;
    flex-shrink: 0;
    overflow: hidden;
}

.file-loader__file img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.file-loader__file:hover {
    cursor: pointer;
}

.file-loader-button-add-icon {
    width: 35px;
    height: 35px;
    fill: rgba(30, 136, 229, 1);
}

.file-loader__hint {}

.file-loader__hint--warning {
    color: rgba(255, 84, 71, 1);
}