.guide {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    font-size: 11px;
    font-weight: 200;

    @media (min-width: 1200px) {
        font-size: 14px;
    }
}

.guide--breadcrumbs {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
  height: 20px;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  width: 213px;
  margin-top: 40px;
  margin-bottom: 20px;

  @media (min-width: 1200px){
    margin-top: 80px;
    margin-bottom: 50px;
    font-size: 20px;
    margin-left: 1.2%;
  }
}

.guide:hover {
    cursor: pointer;
}

.guide__box {
  margin: 80px auto;

  @media (min-width: 1200px) {
    margin: 150px auto;
  }
}

.border {
  border: 3px solid #00af64;
 }

 .guide-title {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;

  max-width: 65%;

  @media (min-width: 1200px) {
      font-size: 20px;
  }
}

.guide--navigation {
  display: flex;
  flex-wrap: wrap;
  column-gap: 45px;
  margin-bottom: -45px;
  margin-top: 90px;

  @media (min-width: 1200px) {
    margin-top: 150px;
  }
}

.guide--navigation__card {
  width: 140px;
  height: 140px;
  background-color: #1a1f32;
  box-shadow: rgba(30, 136, 229, 0.7) 0px 8px 40px 0px;
  border-radius: 12px;
  // padding: 50px 2px 10px 8px;
  margin-bottom: 45px;
  margin-left: 1%;
  position: relative;
  border: solid rgba(255, 84, 71, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1200px) {
    width: 240px;
    height: 240px;
    margin-left: 1.2%;
  }
}

.guide--navigation__card:hover {
  background-color: #252a47;
  cursor: pointer;
}

.guide--navigation__card__title {
  font-size: 14px;
  font-weight: 300;
  line-height: 15px;
  padding: 0px 13px 0px 13px;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);

  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 20px;
    padding: 0px 40px 0px 40px;
  }
}

.guide--categories_list {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  width: 100vw;
  overflow: scroll;
  position: absolute;
  left: 0;
  z-index: 10;

  @media (min-width: 800px) {
    justify-content: center;
  }

  @media (min-width: 1200px) {
      max-width: 1140px;
      left: auto;
  }
}

.guide--categories_list::-webkit-scrollbar{
  height: 0;
  width: 0;
}

.guide__categories-button:nth-child(1) {
  margin-left: 3%;

  @media (min-width: 400px) {
    margin-left: 6.5%;
  }

  @media (min-width: 800px) {
    margin-left: 1.2%;
  }
}

.guide__categories-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  border: 1px solid #1e88e5;
  border-radius: 33px;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.2em;
  padding: 2px 10px;
  min-width: 135px;
  min-height: 38px;
  color: #fff;
  cursor: pointer;
  font-family: inherit;

  @media (min-width: 1200px) {
      width: inherit;
      min-width: 223px;
      min-height: 53px;
      font-size: 16px;
      font-weight: 400;
  }
}

.guide__categories-button--active {
  background-color: #1e88e5;
  color: #fff;
}

.guide__footer-button {
  position: absolute;
  right: 0px;
  bottom: 14px;
}

.guide__button-go-forward-icon {
  width: 25px;
  height: 25px;
  fill:  rgba(30, 136, 229, 1);
}

.guide__button-go-forward {
  background-color: rgba(30, 136, 229, 0);
  border: 1px solid rgba(30, 136, 229, 1);
  border-radius: 9px;
  padding: 5px;
  color: rgba(30, 136, 229, 1);
  font-size: 12px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  line-height: 14px;
  cursor: pointer;
}

.guide__next-topic-text {
  color: rgba(224, 224, 224, 0.7);
  display: block;
  text-align: left;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 0px;

  @media (min-width: 1200px){
    font-size: 14px;
  }
}

.guide__next-topic-title {
  color: rgba(255, 255, 255, 1);
  display:inline-block;
  text-align: left;
  padding-top: 10PX;
  font-weight: 200;
  font-size: 16px;
  word-wrap: break-word;
  width: 180px;

  @media (min-width: 1200px){
    font-size: 18px;
    width: 234px;
  }
}

.guide__next-topic-title-box {
  position: relative;
  margin-left: auto;
  @media (min-width: 1200px){
    padding-left: 80px;    
  }
}