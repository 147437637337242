.separator {
    height: 0px;
    width: 115px;
    border: none;
    border-top: 1px solid #ffffff80;
    margin: 35px auto;
    opacity: 0.8;
}

.separator--empty {
    border: none;
    margin: 25px auto;
}

.separator--light {
    margin: 10px auto;
}

.separator--blue {
    background-color: #2785d5;
    font-size: 12px;
    font-weight: 400;
    padding: 2px 5px;
    text-transform: uppercase;
    border-radius: 999px;
    margin-left: auto;

    position: relative;
    top: -15px;
    left: 40%;

    padding: 2px 20px;
}