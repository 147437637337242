.car-info__title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    margin-bottom: 20px;

    @media (min-width: 1200px) {
        font-size: 36px;
    }
}

.car-info__title-text {
    display: inline-block;
    max-width: 290px;

    @media (min-width: 1200px) {
        max-width: 1050px;
    }
}

.car-info__title-close {
    position: absolute;
    right: 0;
    top: 2px;
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;

    @media (min-width: 1200px) {
        right: initial;
        top: 10px;
    }
}

.car-info__title-close:hover {
    cursor: pointer;
}

.car-info__title-close {
    background-color: transparent;
    border: none;
    outline: none;
}

.car-info__title-close-icon {
    width: 30px;
    height: 30px;
    fill: #ff5244;
}

.car-info__menu {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    margin-bottom: 50px;

    @media (min-width: 1200px) {
        justify-content: space-between;
    }
}

.car-info__menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: transparent;
    border: 1px solid #1e88e5;
    border-radius: 9px;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.2em;
    padding: 2px 10px;
    width: 48%;
    min-height: 38px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-family: inherit;

    @media (min-width: 1200px) {
        width: inherit;
        min-width: 223px;
        min-height: 53px;
        font-size: 16px;
        font-weight: 400;
    }
}

.car-info__menu-button--active {
    background-color: #1e88e5;
    color: #fff;
}

.car-info__menu-button--parameters {
    order: 1;
}

.car-info__menu-button--maintenances {
    order: 2;

    @media (min-width: 1200px) {
        order: 3;
    }
}

.car-info__menu-button--documents {
    order: 3;

    @media (min-width: 1200px) {
        order: 2;
    }
}

.car-info__menu-button--reminders {
    order: 4;
}

.car-info__content {
    display: flex;
    justify-content: center;
    column-gap: 40px;
    row-gap: 40px;
    flex-wrap: wrap;
}

.car-info__content-item {
    max-width: 360px;
}

.car-info__content-item--wide {
    @media (min-width: 1200px) {
        max-width: 574px;
    }
}