
.list {
    display: flex;
    flex-direction: column;
    row-gap: 13px;
}

.list-item {
    display: flex;
    column-gap: 8px;
    width: 100%;
}

.list-item-element {
    background-color: #141625;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    color: #fff;
    height: 26px;
    padding: 1px 10px;
    outline: none;
    font-family: inherit;
    flex-grow: 1;
}

.list-button {
    font-family: var(--font);
    width: 26px;
    height: 26px;
    border: none;
    outline: none;
    background-color: #141625;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 0;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    flex-shrink: 0;
}

.list-button:hover {
    cursor: pointer;
}

.list-button--add {
    width: 100%;
}

.list-button-icon-edit {
    width: 18px;
    height: 18px;
    fill: rgba(30, 136, 229, 1);
}

.list-button-icon-delete {
    width: 20px;
    height: 20px;
    fill: rgba(255, 84, 71, 1);
}