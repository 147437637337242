.box {
    background-color: #1a1f32;
    box-shadow: rgba(30, 136, 229, 0.7) 0px 8px 40px 0px;
    border-radius: 8px;
    padding: 30px;
    width: 100%;
    position: relative;
}

.box--gray {
    background-color: #1b1f32;
    box-shadow: none;
    border: 1px solid hsla(0, 0%, 100%, 0.5);
    border-radius: 8px;
    padding: 55px 25px;
}

.box--dialog {
    background-color: #121220;
    box-shadow: none;
    border: 1px solid hsla(0, 0%, 100%, 0.5);
    border-radius: 4px;
    padding: 45px 20px;
    max-width: 500px;
    margin: 0 auto;
}

.box--paddings-xl {
    @media (min-width: 1200px) {
        padding: 45px;
    }
}

.box--padded {
    padding-right: 45px;
}

.box__header-buttons {
    position: absolute;
    right: 20px;
    top: 20px;
}