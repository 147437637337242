.burger-menu {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
  
  .burger-menu__user-info {
    display: flex;
    align-items: center;
    column-gap: 22px;
    cursor: pointer;
    padding-left: 19px;
  }
  
  .burger-menu__user-info-icon-profile {
    width: 25px;
    height: 25px;
    fill: rgba(255, 255, 255, 0.5);
  }
  
  .burger-menu__user-info-icon-goto {
    width: 30px;
    height: 30px;
    fill: rgba(30, 136, 229, 1);
    cursor: pointer;
    margin-left: -15px;
  }

  .burger-menu__user-info-details-rating {
    display: flex;
    align-items: center;
    column-gap: 7px;
    font-weight: 100;
  }
  
  .burger-menu__user-info-details-rating-icon-star {
    width: 20px;
    height: 20px;
    fill: rgba(30, 136, 229, 0.7);
  }
  
  .burger-menu__user-info-details-rating-icon-info {
    width: 17px;
    height: 17px;
    fill: rgba(255, 255, 255, 0.5);
    cursor: pointer;
  }
  
  .burger-menu__items-group {
    background-color: rgba(18, 18, 32, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .burger-menu__items-group--dark {
    background-color: rgba(18, 18, 32, 0.7);
  }
  
  .burger-menu__item {
    display: flex;
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    padding: 19px 30px 19px 19px;
    column-gap: 20px;
    align-items: center;
  
    cursor: pointer;
  
    @media (min-width: 1200px) {
      padding: 19px 120px 19px 19px;
    }
  }
  
  .burger-menu__item:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  
  .burger-menu__item--disabled {
    cursor: default;
    color: rgba(255, 255, 255, 0.5);
  }
  
  .burger-menu__item--light {
    padding: 4px 19px;
  }
  
  .burger-menu__item--light:not(:last-child) {
    border: none;
  }
  
  .burger-menu__item-counter {
    color: rgba(30, 136, 229, 1);
    font-weight: 600;
    margin-left: auto;
  }
  
  .burger-menu__item-badge {
    background-color: #2785d5;
    font-size: 12px;
    font-weight: 400;
    padding: 2px 5px;
    text-transform: uppercase;
    border-radius: 999px;
    margin-left: auto;
    transform: translateX(35%);
    color: #fff;
  }

  .burger-menu__item-group-label {
    background-color: #2785d5;
    font-size: 12px;
    font-weight: 400;
    padding: 2px 5px;
    text-transform: uppercase;
    border-radius: 999px;
    margin-left: auto;

    position: relative;
    top: -15px;
    left: 40%;

    padding: 2px 20px;
  }
  
  .burger-menu__item-icon {
  }
  
  .burger-menu__item-icon--25 {
    width: 25px;
    height: 25px;
  }
  
  .burger-menu__item-icon--30 {
    width: 25px;
    height: 25px;
  }
  
  .burger-menu__item-icon--35 {
    width: 25px;
    height: 25px;
  }
  
  .burger-menu__item-icon--green {
    fill: rgba(15, 247, 81, 0.7);
  }
  
  .burger-menu__item-icon--red {
    fill: rgba(255, 84, 71, 0.7);
  }
  
  .burger-menu__item-icon--blue {
    fill: rgba(30, 136, 229, 0.7);
  }
  
  .burger-menu__item-icon--gray {
    fill: rgba(117, 117, 117, 1);
  }
  
  .burger-menu__logo {
    margin: 0 auto;
  
    height: 65px;
  
    @media (min-width: 1200px) {
      width: 199px;
      height: 108px;
    }
  }