.burger {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    font-size: 11px;
    font-weight: 200;
    height: 100%;
    width: fit-content;
    justify-content: center;
    margin-left: 13px;

    @media (min-width: 1200px) {
        font-size: 14px;
    }
}

.burger:hover {
    cursor: pointer;
}

.burger__icon {
    width: 35px;
    height: 35px;
    fill: rgba(30, 136, 229, 1);
    margin-top: -10px;

    @media (min-width: 1200px) {
        width: 60px;
        height: 60px;
    }
}