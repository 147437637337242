.checkbox input[type="checkbox"] {
    display: none;
}

.checkbox {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    line-height: 16px;
    font-weight: 400;
    cursor: pointer;
    user-select: none;
    column-gap: 15px;
    cursor: pointer;
}

.checkbox--blue {
    color: rgba(30, 136, 229, 1);
}

.checkbox__box {
    width: 21px;
    height: 21px;
    flex-shrink: 0;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 3px;
    background-color: transparent;
    position: relative;
    transition: all 0.3s ease;
}

.checkbox__box--blue {
    border: 2px solid rgba(30, 136, 229, 1);
}

.checkbox__box--light {
    font-weight: 200;
}

.checkbox input[type="checkbox"]:checked+.checkbox__box {}

.checkbox__box::after {
    color: rgba(255, 255, 255, 1);
    content: "";
    position: absolute;
    top: -1px;
    left: 6px;
    width: 7px;
    height: 17px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.checkbox__box--blue::after {
    color: rgba(30, 136, 229, 1);
    border: 1px solid rgba(30, 136, 229, 1);
    border-width: 0 2px 2px 0;
    top: -2px;
    left: 5px;
}

.checkbox input[type="checkbox"]:checked+.checkbox__box::after {
    opacity: 1;
}

.checkbox__text {}