.header {

}

.header__menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-between;

  @media (min-width: 1200px) {
    column-gap: 30px;
  }
}

.header__menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  font-size: 11px;
  font-weight: 200;
  position: relative;
  line-height: 115%;
  text-align: center;
  background: transparent;
  color: #fff;
  border: none;
  font-family: inherit;
  cursor: pointer;
  padding: 0;
  width: fit-content;

  @media (min-width: 1200px) {
    font-size: 14px;
    height: 100px;
  }
}

.header__menu-item--disabled {
  cursor: not-allowed;
}

.header__menu-item-icon {
  fill: rgba(15, 247, 81, 1);
}

.header__menu-item-icon--car {
  width: 29px;
  height: 29px;

  @media (min-width: 1200px) {
    width: 50px;
    height: 50px;
  }
}

.header__menu-item-icon--consumables {
  width: 29px;
  height: 29px;

  @media (min-width: 1200px) {
    width: 46px;
    height: 46px;
  }
}

.header__menu-item-icon--book {


  @media (min-width: 1200px) {
    width: 46px;
    height: 46px;
  }
}

.header__menu-item-icon--parts {
  width: 29px;
  height: 29px;

  @media (min-width: 1200px) {
    width: 55px;
    height: 55px;
  }
}

.header__menu-item-icon--service {
  width: 30px;

  @media (min-width: 1200px) {
    width: 50px;
  }
}

.header__menu-item-text {
}

.header__menu-item-label {
  background-color: #1d89e3;
  border-radius: 50px;
  bottom: 24px;
  font-size: 10px;
  font-weight: 300;
  padding: 1px 3px;
  position: absolute;
  right: -1px;
  text-transform: uppercase;

  @media (min-width: 1200px) {
    bottom: 34px;
    font-size: 14px;
    padding: 1px 5px;
  }
}

.header--empty {
  display: flex;
  justify-content: flex-end;

  /*
        @todo
        Фикс проблемы жестко заданных отступов между
        карточками навигации. Жесткие ограничения приводят
        к тому что блок с карточками расположен не по центру экрана.

        Вследствие этого бургер выходить за правую границу
        блока с карточками навигации.
    */
  padding-right: 37px;
}