.select {
    width: 100%;
    height: 30px;
    position: relative;

    background-color: #141625;
    border-radius: 4px;
    color: #fff;
    font-family: inherit;
    outline: none;
    text-align-last: left;
}

.select__current {
    display: flex;
    justify-content: space-between;
    padding: 0;
    width: 100%;
    height: 30px;
    cursor: pointer;
    position: relative;

    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    background-color: rgba(18, 18, 32, 0.7);
}

.select__current--not-allowed {
    cursor: not-allowed;
}

.select__current--allowed {
    cursor: pointer;
}

.select__current-text {
    margin: auto 25px auto 0;

    font-family: Geologica, ui-sans-serif, system-ui, -system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    text-wrap: nowrap;
    overflow: hidden;
}

.select__current-text:empty:before {
    content: var(--placeholder);
}

.select__icon {
    top: 50%;
    position: absolute;
    right: 10px;
    transform: translateY(-50%);
    flex-shrink: 0;
}

.select__icon--rotated {
    transform: translateX(-50%) rotate(-90deg) !important;
}

.select__icon--fixed-top {
    top: 15px !important;
    left: 50% !important;
    transform: translateX(-50%);
}

.select__list {
    display: block;
    position: relative;
    z-index: 10;
    max-height: 300px;
    overflow: auto;

    width: 100%;
    margin-top: 5px;

    display: none;

    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    background-color: rgba(18, 18, 32, 1);
}

.select__list--showed {
    display: block;
}

.select__item {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 0;
    width: 100%;

    font-family: Geologica, ui-sans-serif, system-ui, -system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    color: #fff;
    font-size: 13px;
    font-weight: 400;

    background-color: rgba(18, 18, 32, 1);
    border: none;
    cursor: pointer;
    position: relative;
}

.select__item-element {
    position: relative;
    padding: 10px;
    transition: background-color 0.15s linear;
}

.select__item-element:hover {
    background-color: #20243d;
}

.select__item--padded {
    padding-left: 30px;
}

.select__item-text {
    text-align: left;
    width: 85%;
}

.select__item-subtext {
    color: rgba(255, 255, 255, 0.5);
}

.select__item-checkbox {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.select__item-show-childs {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 30px;
    padding: 10px 0 0 7px;
    transition: background-color 0.15s linear;
}

.select__item-show-childs:hover {
    background-color: #20243d;
}

.select__item-childs {}