.spinner {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px dashed teal;
    animation: rotate 1s infinite linear;
    margin: 0 auto;
}

@keyframes rotate {
    from {
        transform: rotate(0deg) scale(0.5);
    }

    to {
        transform: rotate(360deg) scale(1);
    }
}