.breadcrumbs {
    list-style: none;
    padding: 0;
    display: flex;
    column-gap: 10px;
    color: rgba(224, 224, 224, 0.7);
    font-size: 12px;
    font-weight: 100;

    @media (min-width: 1200px) {
        font-size: 14px;
    }
}

.breadcrumbs li:not(:last-child)::after {
    content: ">";
    margin-left: 10px;
    text-wrap: nowrap;
}

.breadcrumbs .link {
    cursor: pointer;
}