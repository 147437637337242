.consumables_analytics-switch {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  margin-bottom: 50px;
  justify-content: center;
}

.consumables_analytics-switch_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  font-weight: 300;
  position: relative;
  line-height: 20%;
  text-align: center;
  background: transparent;
  color: #ffffffcc;
  border: none;
  font-family: inherit;
  cursor: pointer;
  width: fit-content;
  gap: 20px;
  margin: 0 20px 0 20px;
  padding-bottom: 20px;

  @media (min-width: 1200px) {
    font-size: 14px;
    height: 80px;
  }
}

.consumables_analytics-switch_button--active{
  color: #fff;
  font-weight: 400;
  border-bottom: solid #1e88e5 1px;
}

.consumables_analytics-switch_button--icon--expenses {
  width: 29px;
  height: 29px;
  @media (min-width: 1200px) {
    width: 80px;
    height: 100px;
  }
}

.consumables_analytics-switch_button--icon--analytics {
  width: 29px;
  height: 29px;
  fill: #ffffffcc;

  @media (min-width: 1200px) {
    width: 80px;
    height: 100px;
  }
}

.consumables_car_info{
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  column-gap: 40px;
  align-items: center;
  
  flex-direction: column;

  :last-child{
    order: -1;
  }
  @media (min-width: 800px) {
    flex-direction: row;
    :last-child{
      order: 2;
}}
}

.consumables_button-switch {
  display: flex;
  margin-right: 10%;
  flex-direction: row-reverse;

  color: rgba(255, 255, 255, 1);

  @media (min-width: 800px) {
    flex-direction: row;
    margin: 0;
  }
}

.consumables_button-switch__item {
  background-color: #131220;
  border: 1px solid rgba(30, 136, 229, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 33px;
  padding: 3px 40px;
  position: relative;
  flex-grow: 1;
  text-wrap: nowrap;
  font-family: var(--font);
  font-family: inherit;
  font-size: 12px;

  margin-right: -35px;
}

.consumables_button-switch__item--active {
  background-color: rgba(30, 136, 229, 1);
  z-index: 2;
}