@use "../../styles/sanitize.scss";

@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');

html {
  --font: Geologica, ui-sans-serif, system-ui, -system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

body {
  font-family: var(--font);
  font-size: 16px;
  color: #fff;
  background-color: #131220;
}