.tabs {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 20px;
    list-style-type: none;
    padding: 0;
    font-size: 14px;
    margin: -10px auto 50px;
    width: 100%;
}

.tabs-item {
    cursor: pointer;
}

.tabs-item--active {
    border: 1px solid rgba(30, 136, 229, 1);
    border-radius: 33px;
    color: rgba(30, 136, 229, 1);
    padding: 3px 10px;
    font-size: 14px;
    cursor: default;
}

.tabs-item--hide-desktop {
    display: list-item;

    @media (min-width: 1200px) {
        display: none;
    }
}