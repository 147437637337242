.form {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    font-family: inherit;
    color: rgba(224, 224, 224, 0.8);
    font-size: 14px;
    font-weight: 100;
}

.form--big-gaps {
    row-gap: 30px;
}

.form__header {
    display: flex;
    margin-bottom: 30px;
    position: relative;

    align-items: center;
    justify-content: space-between;
}

.form__header-title {
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-transform: uppercase;

    max-width: 45%;

    @media (min-width: 1200px) {
        font-size: 20px;
    }
}

.form__header-title--big {
    font-size: 20px;

    @media (min-width: 1200px) {
        font-size: 26px;
    }
}

.form__header-title--big-extra {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;

    @media (min-width: 1200px) {
        line-height: 38px;
        font-size: 34px;
    }
}

.form__header-button {
    position: absolute;
    right: 0px;
}

.form__item {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    position: relative;
    width: 100%;
}

.form__item--row {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.form__item--required label::after {
    content: "*";
    margin-left: 5px;
    color: rgba(30, 136, 229, 1);
}

.form__item input:not([type="submit"]),
.form__item select,
.form__item option {
    background-color: #141625;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    color: #fff;
    height: 30px;
    padding: 3px 10px;
    outline: none;
    font-family: inherit;
}

.form__item-span {
    background-color: #141625;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    color: #fff;
    padding: 3px 10px;
    outline: none;
    font-family: inherit;

    padding: 5px 10px;
    width: 100%;
    min-height: 30px;

    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

.form__item-span-element {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.form__item--wide {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-weight: 300;

    @media (min-width: 1200px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.form__item--wide-start {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-weight: 300;

    @media (min-width: 1200px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: start;
    }
}

.form__item--wide-center {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-weight: 300;

    @media (min-width: 1200px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

.form__item-label {}

.form__item-label--wide {
    @media (min-width: 1200px) {
        width: 30%;
    }
}

.form__item-element {
    display: flex;
    column-gap: 10px;
    flex-shrink: 0;
}

.form__item-element input:not([type="submit"]),
.form__item-element select {
    flex-grow: 1;
}

.form__item-element--wide {
    @media (min-width: 1200px) {
        width: 70%;
    }
}

.form__item-element--multiple {
    display: inline-flex;
    flex-direction: column;
    row-gap: 20px;

    @media (min-width: 1200px) {
        width: fit-content;
        margin-right: auto;
    }
}

.form__item-element--spark {
    position: relative;
}

.form__item-element--spark::after {
    content: "*";
    font-size: 20px;
    color: rgba(30, 136, 229, 1);
    position: absolute;
    right: -25px;
    bottom: -2px;

    @media (min-width: 1200px) {
        right: inherit;
        left: -25px;
    }
}

.form__item-element-postfix {
    width: 18px;
    height: 18px;
}

.form__item-element-postfix--big {
    width: 40px;
    height: 40px;
}

.form__item-postfix--interactive:hover {
    cursor: pointer;
}

.form__item-element-prefix {
    width: 100%;
}

.form__item-postfix-icon-copy {
    opacity: 0.3;
    margin-bottom: -5px;
}

.form__item-postfix-icon-copy:hover {
    opacity: 0.5;
}

.form__item-postfix-icon-copy:focus {
    opacity: 0.7;
}

.form__item-postfix-icon-warning {
    fill: rgba(255, 84, 71, 1);

    @media (min-width: 1200px) {
        left: 328px;
    }
}

.form__buttons {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 20px;

    @media (min-width: 1200px) {
        flex-direction: row;
        justify-content: center;
        column-gap: 40px;
        margin-left: 50px;
    }
}

.form__item textarea {
    background-color: #141625;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    font-family: inherit;
    color: #fff;
    width: 100%;
    height: 100px;
    outline: none;
    padding: 10px;
}

.form__item input:focus {
    background-color: #20243d;
    outline: none;
}

.form__item-button {
    border: 1px solid hsla(0, 0%, 100%, .5);
    border-radius: 4px;
    color: hsla(0, 0%, 100%, .7);
    flex-shrink: 0;
    font-size: 14px;
    padding: 0;
    width: 30px;
    background-color: #141625;
    height: 30px;
    outline: none;
}

.form__item-button:hover {
    cursor: pointer;
}

.form__item-button-icon {
    width: 20px;
    height: 20px;
}

.form__item-message {
    display: flex;
    column-gap: 10px;
}

.form__item-message-link a {
    color: rgba(30, 136, 229, 1);
    font-weight: 400;
}

.form__hint {
    text-align: center;
}

.form__hint--green {
    color: #14b649;
}

.form__hint--red {
    color: #f55442
}