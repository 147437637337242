.container {
    min-width: 380px;
}

.container--xl {
    max-width: 1140px;
}

.container--centered {
    margin: auto;
}