@use "../../styles/main.scss";

.layout {
    min-width: 380px;
  }
  
  .layout a {
    color: inherit;
    text-decoration: none;
  }
  
  .layout__header {
    max-width: 360px;
    margin: 25px auto 30px;

    @media (min-width: 1200px) {
      max-width: 460px;
      margin: 50px auto 70px;
    }
  }
  
  .layout__header--big {
    max-width: 360px;

    @media (min-width: 1200px) {
      max-width: 1100px;
    }
  }
  
  .layout__main {
    max-width: 360px;
  
    margin: 0 auto;
  
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }
  
  .layout__footer {
    max-width: 360px;
  
    margin: 85px auto;
  
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }