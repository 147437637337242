.button {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  text-decoration: none;

  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.button--fit {
  @media (min-width: 1200px) {
    width: auto;
  }
}

.button--fit-all {
  width: auto;
}

.button--primary,
.button--primary input {
  background-color: #14b64a;
  outline: none;
  border: 1px solid #14b64a;
  border-radius: 33px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: inherit;
  transition: hover 0.3s;
  padding: 7px 35px;
  cursor: pointer;
}

.button--primary:hover {
  background-color: transparent;
}

.button--paddings-min {
  padding: 7px 15px;
}

.button--light {
  color: rgba(224, 224, 224, 0.7);
  font-size: 14px;
  font-family: inherit;
}

.button--light-red {
  color: rgba(255, 84, 71, 1);
  font-size: 14px;
  font-family: inherit;
}

.button--light-white {
  color: #fff;
  font-size: 14px;
  font-family: inherit;
}

.button--tile {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(18, 18, 32, 0.5);
  font-family: inherit;
  border-radius: 6px;
  padding: 12px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
}

.button--tile--active {
  background-color: #1d89e4;
  border: 1px solid #1d89e4;
}

.button--tile--hover-active:hover {
  background-color: #1d89e4;
  border: 1px solid #1d89e4;
  color: rgba(255, 255, 255, 1);
}

.button--blue {
  background-color: transparent;
  border: 1px solid rgba(30, 136, 229, 1);
}

.button--ligth-blue {
  background-color: transparent;
  border: 1px solid rgba(30, 136, 229, 1);
  border-radius: 0.4em;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  line-height: 16px;

  @media (min-width: 1200px) {
    font-size: 16px;
    padding: 10px;
  }
}

.button__icon {
  width: 20px;
  height: 20px;
  fill: #eb584d;
  margin-left: -20px;
}

.button-go-back {
  background-color: rgba(18, 18, 32, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 9px;
  padding: 5px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  line-height: 14px;
  cursor: pointer;
}

.button-go-back-icon {
  width: 25px;
  height: 25px;
  fill: #7c7f87;
  transform: rotate(180deg);
}

.button-switch {
  display: flex;

  font-family: inherit;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
}

.button-switch__item {
  background-color: #131220;
  border: 1px solid rgba(30, 136, 229, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 33px;
  padding: 12px 60px;
  position: relative;
  flex-grow: 1;
  text-wrap: nowrap;
  font-family: var(--font);
}

.button-switch__item:not(:last-child) {
  margin-right: -35px;
}

.button-switch__item--active {
  background-color: rgba(30, 136, 229, 1);
  z-index: 2;
}

.button-hint {
  color: #1e88e5;
  font-size: 12px;
  font-weight: 100;
  padding: 0 10px;
  text-align: center;
  max-width: 300px;
}