.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.7);

    display: none;
    z-index: 10;
}

.modal--showing {
    display: block;
}


.modal__content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100vh;
    overflow-y: auto;
    padding: 50px 15px;
}

.dialog {
    // display: inline-flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    position: relative;
}

.dialog--hint {
    font-size: 14px;
    color: rgba(224, 224, 224, 0.7);
    font-weight: 100;
    line-height: 14px;
    row-gap: 10px;
    max-width: 364px;
}

.dialog__close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 8px;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.dialog__content {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
}

.dialog__title {
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: rgba(30, 136, 229, 1);
    align-self: start;
}

.dialog__title-icon {
    width: 20px;
    height: 20px;
    fill: rgba(255, 255, 255, 0.5);
}

.dialog__text {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;

    @media (min-width: 1200px) {
        font-size: 16px;
        font-weight: 400;
    }
}

.dialog__text--gray {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
}

.dialog__text--with-icon {
    margin-right: 15px;
}

.dialog__icon {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 25px;
}

.dialog__icon-success {
    color: #27e369;
}

.dialog__icon-fail {
    color: rgba(255, 84, 71, 1);
}

.dialog__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    width: 100%;
}

.dialog__button {
    background-color: transparent;
    border: 1px solid rgba(30, 136, 229, 0.7);
    border-radius: 33px;
    padding: 5px 25px;
    width: 100%;

    color: rgba(255, 255, 255, 0.8);
    font-family: inherit;
    font-size: 12px;
    font-weight: 200;
    line-height: 14px;
    text-wrap: nowrap;

    position: relative;

    cursor: pointer;
}

.dialog__button--with-icon {
    padding-left: 35px;
    padding-right: 25px;
}

.dialog__button--big {
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.dialog__button-icon {
    position: absolute;
    left: 8%;
    top: 50%;
    transform: translateY(-50%);
}

.dialog__hint {
    color: rgba(30, 136, 229, 1);
    font-size: 11px;
    font-weight: 100;
    text-align: center;
    padding: 0 10px;

    @media (min-width: 1200px) {
        font-size: 12px;
    }
}